export const PAGE_URLS = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',

  HOME: '/',
  DASHBOARD: '/dashboard',
  STRIPE_ONBOARDING: '/stripe-onboarding',
  LEVEL_ONBOARDING: '/level-onboarding',

  SUBSCRIBER: '/subscriber',
  SUBSCRIBER_LIST: '/subscriber/list',
  SUBSCRIBER_CREATE: '/subscriber/create',
  SUBSCRIBER_INFO: '/subscriber/info',
  SUBSCRIBER_IMPORT: '/subscriber/import',

  EMPLOYER: '/employer',
  EMPLOYER_LIST: '/employer/list',
  EMPLOYER_CREATE: '/employer/create',

  EMPLOYEE: '/employee',
  EMPLOYEE_LIST: '/employee/list',
  EMPLOYEE_CREATE: '/employee/create',
  EMPLOYEE_INFO: '/employee/info',

  REVSHARE_INFO: '/revshare-info',
  REVSHARE_LIST: '/revshare-list',

  PARTNER_LIST: '/partner-plan', // TODO - Upadte it to /partner/list. Same applies for group, region, location
  CREATE_PARTNER: '/create-partner',
  PARTNER_INFO: '/partner-info',

  CREATE_GROUP: '/create-group',
  GROUP_LIST: '/group-list',
  GROUP_INFO: '/group-info',
  SETTINGS: '/settings',

  REGION_LIST: '/region-list',
  CREATE_REGION: '/create-region',
  REGION_INFO: '/region-info',

  LOCATION_LIST: '/location-list',
  CREATE_LOCATION: '/create-location',
  LOCATION_INFO: '/location-info',

  PLANS: '/plans',
  PLANS_LIST: '/plans/list',
  PLANS_CREATE: '/plans/create',
  PLANS_INFO: '/plans/info',
  PLANS_EDIT: '/plans/edit',

  USERS: '/users',
  USERS_LIST: '/users/list',
  USERS_CREATE: '/users/create',

  SUPPORT: '/support',
  MARKETING_MATERIALS: '/marketing-materials',
  TRANSFERS_PAYOUT: '/transfers-payout',
  CUSTOM_REPORTS: '/custom-reports',
  PROVIDER_PAYMENTS: '/provider-payments',
  ENROLLMENT_TRENDS: '/enrollment-trends',

  SCHEDULE_TRAINING: '/schedule-training',
  FAQS: '/faqs',
  PLAN_MATERIALS: '/plan-materials',

  PATIENT_LOOKUP: '/patient-lookup',
  SAVINGS_CALCULATOR: '/savings-calculator',

  TERMS_OF_SERVICE: '/terms-of-service',
  HIPAA_NOTICE: '/hipaa-notice',
  PRIVACY_POLICY: '/privacy-policy',

  FLEX_PACKAGE_DASBOARD: '/flex-package/dashboard',
  FLEX_PACKAGE_INVOICES: '/flex-package/invoices',
  FLEX_PACKAGE_INVOICES_LIST: '/flex-package/invoices/list',
  FLEX_PACKAGE_INVOICES_ORDER: '/flex-package/invoices/order',
  FLEX_PACKAGE_INVOICES_INFO: '/flex-package/invoices/info',

  FLEX_PACKAGE_PACKAGES: '/flex-package/packages',
  FLEX_PACKAGE_PACKAGES_LIST: '/flex-package/packages/list',
  FLEX_PACKAGE_PACKAGES_CREATE: '/flex-package/packages/create',
  FLEX_PACKAGE_PACKAGES_EDIT: '/flex-package/packages/edit',
  FLEX_PACKAGE_PACKAGES_INFO: '/flex-package/packages/info',

  FLEX_PAY: '/flex-pay',
  FLEX_PAY_INVOICES: '/flex-pay/invoices',
  FLEX_PAY_INVOICES_LIST: '/flex-pay/invoices/list',
  FLEX_PAY_INVOICES_MANAGE: '/flex-pay/invoices/manage-invoice',
  FLEX_PAY_INVOICES_INFO: '/flex-pay/invoices/info',
  FLEX_PAY_INVOICES_EDIT: '/flex-pay/invoices/edit',
  FLEX_PAY_INVOICES_PAY: '/',
};

export const FLEX_PATIENT_PAYMENT_DOMAINS = [
  'pay.localhost.com',
  'pay.dev.subscribili.com',
  'pay.qa.subscribili.com',
  'staging-pay.subscribili.com',
  'pay.subscribili.com',
];
