import React, { createRef, lazy, Suspense, useEffect, useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { signOut } from 'aws-amplify/auth';

import { Context } from 'context';
import {
  ADD_NOTIFICATION,
  RESET_SITE_DATA,
  UPDATE_PAYMENT_TYPES,
  UPDATE_STRIPE_ONB_STATUS,
} from 'context/actions';
import CommonLoader from 'Views/Common/CommonLoader';
import { ONBOARD_STAGE } from 'Views/Common/enum';
import { Header, SideNav } from 'common-layout';
import { checkIfAdmin } from 'Views/Login/LoginHelper';
import LogoutSession from 'Views/LogoutSession/LogoutSession';
import TermsConditions from 'Views/TermsConditions';
import PrivacyPolicy from 'Views/PrivacyPolicy';
import HipaaNotice from 'Views/HipaaNotice';
import { STRIPE_ONBOARDING_SERVICES, USER_SERVICES } from 'Services';
import { PAGE_URLS } from './constants';

// lazy loading components.
const Dashboard = lazy(() => import('Views/Dashboard/Dashboard'));

const RevshareInfo = lazy(() =>
  import('Views/Revshare/RevshareInfo/RevshareInfo'),
);
const RevshareList = lazy(() =>
  import('Views/Revshare/RevshareList/RevshareList'),
);

const CreateLocation = lazy(() =>
  import('Views/Location/CreateLocation/CreateLocation'),
);
const LocationInfo = lazy(() =>
  import('Views/Location/LocationInfo/LocationInfo'),
);
const LocationList = lazy(() =>
  import('Views/Location/LocationList/LocationList'),
);

const CreatePartner = lazy(() =>
  import('Views/Partner/CreatePartner/CreatePartner'),
);
const PartnerInfo = lazy(() => import('Views/Partner/PartnerInfo/PartnerInfo'));
const PartnerList = lazy(() => import('Views/Partner/PartnerList/PartnerList'));

const Plans = lazy(() => import('Views/Plans'));

const ScheduleTraining = lazy(() =>
  import('Views/ScheduleTraining/ScheduleTraining'),
);
const FAQs = lazy(() => import('Views/FAQ/FAQs'));

const CreateGroup = lazy(() => import('Views/Group/CreateGroup/CreateGroup'));
const GroupListing = lazy(() => import('Views/Group/GroupList/GroupListing'));
const GroupInfo = lazy(() => import('Views/Group/GroupInfo/GroupInfo'));

const RegionInfo = lazy(() => import('Views/Region/RegionInfo/RegionInfo'));
const RegionListing = lazy(() =>
  import('Views/Region/RegionList/RegionListing'),
);
const CreateRegion = lazy(() =>
  import('Views/Region/CreateRegion/CreateRegion'),
);

const Employer = lazy(() => import('Views/Employer'));
const Employee = lazy(() => import('Views/Employee'));

const Users = lazy(() => import('Views/Users'));

const GroupSetting = lazy(() => import('Views/Settings/GroupSetting'));

const StripeOnboarding = lazy(() => import('Views/Stripe'));

const LevelOnboarding = lazy(() => import('Views/LevelOnboarding'));

const Support = lazy(() => import('Views/Support'));
const Marketing = lazy(() => import('Views/Marketing'));

const Transactions = lazy(() => import('Views/Transactions'));

// const CustomReports = lazy(() => import("Views/CustomReports"));
const CustomReportsV2 = lazy(() => import('Views/CustomReportsV2'));

const TransferReports = lazy(() => import('Views/TransferReports'));

const EnrollmentTrends = lazy(() => import('Views/EnrollmentTrends'));

const Subscriber = lazy(() => import('Views/Subscriber'));

const SubscriptionMaterials = lazy(() => import('Views/SubscriptionMaterials'));

const ExternalPatientInfo = lazy(() => import('Views/ExternalPatientInfo'));

const SavingsCalculator = lazy(() =>
  import('Views/SavingsCalculator/SavingsCalculator'),
);

/* Flex Pay - V1 */
const FlexPackagePackages = lazy(() => import('Views/FlexPay/Packages'));
const FlexPackageInvoices = lazy(() => import('Views/FlexPay/Invoices'));

/* Flex Pay - V2 */
const FlexPayRoot = lazy(() => import('Views/FlexPayV2/FlexPayRoot'));

const Main = () => {
  const {
    state: { user, logoutUser, logoutLoading, stripeOnboard },
    dispatch,
  } = useContext(Context);

  const { refresh: stripeRefresh = false, init: stripeInit = false } =
    stripeOnboard;

  const { subscribili_tc_accepted_flag, onboard_stage = '' } =
    user?.settings?.settings || {};

  const { features: userFeatures = [] } = user?.details || {};

  const ref = createRef();
  const history = useHistory();
  const { pathname } = useLocation();

  const isStripeOnboarding = pathname.includes(PAGE_URLS.STRIPE_ONBOARDING);
  const isLevelOnboarding = pathname.includes(PAGE_URLS.LEVEL_ONBOARDING);
  const isScheduleTraining = pathname.includes(PAGE_URLS.SCHEDULE_TRAINING);
  const isOnboarding = isStripeOnboarding || isLevelOnboarding;
  const admin_level_roles = checkIfAdmin(user);
  const isOnboardingUser = userFeatures.includes('onb');

  const setGlobalNotification = (severity, message) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        notification: {
          severity,
          message,
        },
      },
    });

  const redirectToPage = (route, state = {}) => {
    history.push({
      pathname: route,
      state,
    });
  };

  const getLevelData = (data) => {
    const {
      partner_id = '',
      group_id = '',
      region_id = '',
      location_id = '',
    } = data;

    if (location_id) {
      return {
        level: 'location',
        label: 'Location',
        level_id: location_id,
        partner_id,
      };
    } else if (region_id) {
      return {
        level: 'region',
        label: 'Region',
        level_id: region_id,
        partner_id,
      };
    } else if (group_id) {
      return {
        level: 'group',
        label: 'Group',
        level_id: group_id,
        partner_id,
      };
    } else if (partner_id) {
      return {
        level: 'partner',
        label: 'Partner',
        level_id: partner_id,
        partner_id,
      };
    }
  };

  const getProcessedData = (data = {}) => {
    const { locations = [], regions = [], groups = [], partners = [] } = data;

    const allLevels = {
      locations,
      regions,
      groups,
      partners,
    };

    const processedData = {};
    Object.keys(allLevels).forEach((levelKey) => {
      const levelData = allLevels?.[levelKey] || [];

      const pData = levelData.map((indLevel) => {
        const {
          level = 'location',
          level_id = '',
          label = 'Location',
        } = getLevelData(indLevel);
        const name = indLevel?.[`${level}_name`] || '';
        const upadetdData = { ...indLevel, level, level_id, label, name };

        return {
          ...upadetdData,
          level_data: upadetdData,
          action: upadetdData,
        };
      });

      processedData[levelKey] = pData;
    });

    return processedData;
  };

  const fetchOnboardingLevels = async () => {
    dispatch({
      type: UPDATE_STRIPE_ONB_STATUS,
      payload: {
        refresh: false,
        loading: true,
      },
    });

    try {
      const response = await STRIPE_ONBOARDING_SERVICES.getOnboardingLevels();

      if (response?.type === 'success' && response?.data) {
        const { stats = {}, ...otherData } = response.data;
        const { pending = 0 } = stats;

        if (pending) {
          const processedData = getProcessedData(otherData) || {};

          dispatch({
            type: UPDATE_STRIPE_ONB_STATUS,
            payload: {
              init: true,
              loading: false,
              stats,
              ...processedData,
            },
          });

          redirectToPage(PAGE_URLS.STRIPE_ONBOARDING);

          return;
        } else {
          isStripeOnboarding && redirectToPage(PAGE_URLS.DASHBOARD);
        }
      } else {
        throw response?.error || response?.message;
      }
    } catch (err) {
      err && setGlobalNotification('error', err);
    }

    dispatch({
      type: UPDATE_STRIPE_ONB_STATUS,
      payload: {
        init: true,
        loading: false,
      },
    });
  };

  const fetchPaymentTypeList = async () => {
    dispatch({
      type: UPDATE_PAYMENT_TYPES,
      payload: {
        loading: true,
      },
    });

    try {
      const pResponse = await USER_SERVICES.getPaymentTypes();

      if (pResponse?.type === 'success') {
        dispatch({
          type: UPDATE_PAYMENT_TYPES,
          payload: {
            loading: false,
            list: pResponse?.data,
          },
        });
      } else {
        throw pResponse;
      }
    } catch (err) {
      console.log(
        err?.message || err?.error || 'Error while fetching payment types',
      );

      dispatch({
        type: UPDATE_PAYMENT_TYPES,
        payload: {
          loading: false,
        },
      });
    }
  };

  useEffect(() => {
    stripeRefresh && fetchOnboardingLevels();
  }, [stripeRefresh]);

  useEffect(() => {
    // Get payment types
    fetchPaymentTypeList();

    // stripe-onboarding validation
    if (admin_level_roles && isOnboardingUser) {
      fetchOnboardingLevels();

      return;
    }

    dispatch({
      type: UPDATE_STRIPE_ONB_STATUS,
      payload: {
        init: true,
      },
    });
  }, []);

  useEffect(() => {
    if (
      admin_level_roles &&
      ![ONBOARD_STAGE.COMPLETED, ''].includes(onboard_stage) &&
      !subscribili_tc_accepted_flag
    ) {
      redirectToPage(PAGE_URLS.LEVEL_ONBOARDING);

      return;
    }

    if (
      (isLevelOnboarding &&
        (!admin_level_roles || subscribili_tc_accepted_flag)) ||
      (isStripeOnboarding && (!admin_level_roles || !isOnboardingUser))
    ) {
      redirectToPage(PAGE_URLS.DASHBOARD);

      return;
    }

    ref.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  const getWrapperClassName = () => {
    if (isStripeOnboarding) return 'stripe_onboard_wrapper';
    else if (isLevelOnboarding) return 'location_onboard_wrapper';
    else if (isScheduleTraining)
      return 'schedule_training_wrapper dashboard_wrapper';

    return 'dashboard_wrapper';
  };

  const handleLogOut = async () => {
    try {
      await signOut();

      window.zE?.('messenger', 'logoutUser');

      // window?.fcWidget?.user
      //   .clear()
      //   .then(() => {
      //     console.log("Freshchat User cleared");
      //   })
      //   .catch(() => {
      //     console.log("Freshchat User Not cleared");
      //   })
      //   .finally(() => {
      //     window?.fcWidget?.destroy();
      //   });

      localStorage.setItem('subscribiliToken', '');
      localStorage.setItem('userDetail', '');
      localStorage.setItem('tokenValidity', '');

      // FlexPay - Clearing the access token
      localStorage.setItem('accessToken', '');

      dispatch({
        type: RESET_SITE_DATA,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    logoutUser && handleLogOut();
  }, [logoutUser]);

  return (
    <div className={logoutLoading ? 'disable-form' : undefined}>
      {!isOnboarding && <Header />}
      <main className="d-flex">
        {!isOnboarding && <SideNav />}

        <div className={getWrapperClassName()} ref={ref}>
          <Suspense fallback={<CommonLoader />}>
            <Switch>
              {!stripeInit && <CommonLoader />}
              <Route path={PAGE_URLS.HOME} exact component={Dashboard} />
              <Route path={PAGE_URLS.DASHBOARD} exact component={Dashboard} />
              <Route
                path={PAGE_URLS.STRIPE_ONBOARDING}
                component={StripeOnboarding}
              />
              <Route
                path={PAGE_URLS.LEVEL_ONBOARDING}
                component={LevelOnboarding}
              />
              <Route path={PAGE_URLS.SUBSCRIBER} component={Subscriber} />

              <Route
                path={PAGE_URLS.SCHEDULE_TRAINING}
                component={ScheduleTraining}
              />
              <Route path={PAGE_URLS.FAQS} component={FAQs} />
              <Route
                path={PAGE_URLS.PLAN_MATERIALS}
                component={SubscriptionMaterials}
              />

              <Route path={PAGE_URLS.EMPLOYER} component={Employer} />
              <Route path={PAGE_URLS.EMPLOYEE} component={Employee} />

              <Route
                path={PAGE_URLS.REVSHARE_INFO}
                exact
                component={RevshareInfo}
              />
              <Route
                path={PAGE_URLS.REVSHARE_LIST}
                exact
                component={RevshareList}
              />

              {/* PARTNER */}
              <Route
                path={PAGE_URLS.PARTNER_LIST}
                exact
                component={PartnerList}
              />
              <Route
                path={PAGE_URLS.CREATE_PARTNER}
                exact
                component={CreatePartner}
              />
              <Route
                path={PAGE_URLS.PARTNER_INFO}
                exact
                component={PartnerInfo}
              />
              {/* GROUP */}
              <Route
                path={PAGE_URLS.GROUP_LIST}
                exact
                component={GroupListing}
              />
              <Route
                path={PAGE_URLS.CREATE_GROUP}
                exact
                component={CreateGroup}
              />
              <Route path={PAGE_URLS.GROUP_INFO} exact component={GroupInfo} />
              <Route path={PAGE_URLS.SETTINGS} exact component={GroupSetting} />
              {/* REGION */}
              <Route
                path={PAGE_URLS.REGION_LIST}
                exact
                component={RegionListing}
              />
              <Route
                path={PAGE_URLS.CREATE_REGION}
                exact
                component={CreateRegion}
              />
              <Route
                path={PAGE_URLS.REGION_INFO}
                exact
                component={RegionInfo}
              />
              {/* LOCATION */}
              <Route
                path={PAGE_URLS.CREATE_LOCATION}
                exact
                component={CreateLocation}
              />
              <Route
                path={PAGE_URLS.LOCATION_LIST}
                exact
                component={LocationList}
              />
              <Route
                path={PAGE_URLS.LOCATION_INFO}
                exact
                component={LocationInfo}
              />

              <Route path={PAGE_URLS.PLANS} component={Plans} />
              <Route path={PAGE_URLS.USERS} component={Users} />
              <Route path={PAGE_URLS.SUPPORT} exact component={Support} />
              <Route
                path={PAGE_URLS.MARKETING_MATERIALS}
                exact
                component={Marketing}
              />
              <Route
                path={PAGE_URLS.TRANSFERS_PAYOUT}
                exact
                component={Transactions}
              />
              <Route
                path={PAGE_URLS.CUSTOM_REPORTS}
                exact
                component={CustomReportsV2}
              />
              <Route
                path={PAGE_URLS.PROVIDER_PAYMENTS}
                exact
                component={TransferReports}
              />
              <Route
                path={PAGE_URLS.ENROLLMENT_TRENDS}
                exact
                component={EnrollmentTrends}
              />

              {/* PATIENT SEARCH */}
              <Route
                path={PAGE_URLS.PATIENT_LOOKUP}
                exact
                component={ExternalPatientInfo}
              />
              <Route
                path={PAGE_URLS.SAVINGS_CALCULATOR}
                exact
                component={SavingsCalculator}
              />

              {/* COMMON ROUTES */}
              <Route
                path={PAGE_URLS.TERMS_OF_SERVICE}
                exact
                component={TermsConditions}
              />
              <Route
                path={PAGE_URLS.HIPAA_NOTICE}
                exact
                component={HipaaNotice}
              />
              <Route
                path={PAGE_URLS.PRIVACY_POLICY}
                exact
                component={PrivacyPolicy}
              />

              {/* FLEX PACKAGE */}
              <Route
                path={PAGE_URLS.FLEX_PACKAGE_INVOICES}
                component={FlexPackageInvoices}
              />
              <Route
                path={PAGE_URLS.FLEX_PACKAGE_PACKAGES}
                component={FlexPackagePackages}
              />

              {/* FLEX PAY */}
              <Route path={PAGE_URLS.FLEX_PAY} component={FlexPayRoot} />

              {/* <Redirect to="/dashboard" /> */}
            </Switch>
          </Suspense>
        </div>
      </main>

      <LogoutSession />
    </div>
  );
};

export default Main;
