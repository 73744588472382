import React, { useContext, useState, useRef } from 'react';
import clsx from 'clsx';
import {
  AppBar,
  Button,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ColorLens as ColorLensIcon,
  ExitToApp as ExitToAppIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
  Tune as TuneIcon,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import Avatar from 'react-avatar';

// Assets
import createIcon from '../../assets/images/createSubs.svg';
import termsIcon from '../../assets/images/termsIcon.svg';
// import Logo from 'assets/images/logo.svg';

// Components
import { DividerWithText, RoleValidator } from 'components';
import { ThemeChangeModal } from './components';
import FilterModal from 'Views/Common/Custom/FilterModal';
import FlexFilterModal from 'Views/Common/FlexFilterModal/FlexFilterModal';
import OutsideClick from 'Views/Common/OutsideClick';

// Context
import { Context } from 'context';
import {
  LOGOUT,
  SUBSCRIBERS_FILTERS_UPDATED,
  UPDATE_INVOICE_FILTERS,
} from 'context/actions';

// Constants
import {
  PARTNER_ADMIN,
  GROUP_ADMIN,
  REGION_ADMIN,
  LOCATION_ADMIN,
} from 'global-constants/roles';
import {
  GLOBAL_SEARCH_SUGGESTIONS,
  GLOBAL_SEARCH_MATCHES,
  FLEX_SEARCH_SUGGESTIONS,
  FLEX_SEARCH_MATCHES,
} from 'Views/Common/enum';
import { PAGE_URLS } from 'Routes/Main/constants';

// Utils
import Utils from 'Shared/Utils';

// Styles
import styles from './Header.module.scss';
import './styles.scss';

const Header = () => {
  const {
    state: {
      user = {},
      domainConfig: { logo, logoAlt = 'logo' },
      subscriberFilters,
      invoiceFilters,
      dashboardFilters = {},
      menu_config: { selected_product = {} } = {},
    },
    dispatch,
  } = useContext(Context);

  const { filterState: subscriberFilterState } = subscriberFilters;
  const { filterState: invoiceFilterState } = invoiceFilters;
  const { groupId = '', partnerId = '' } = dashboardFilters;

  const [isOpen, setIsOpen] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [termsAnchorEl, setTermsAnchorEl] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [updateTheme, setThemeUpdate] = useState(false);

  const [searchType, setSearchType] = useState({});
  const [searchValue, setSearchValue] = useState('');

  const termsMenu = [
    {
      title: 'Terms of Service',
      linkTo: '/terms-of-service',
    },
    {
      title: 'Privacy Policy',
      linkTo: '/privacy-policy',
    },
    {
      title: 'HIPAA Notice',
      linkTo: '/hipaa-notice',
    },
  ];

  const ref = useRef();
  const history = useHistory();

  OutsideClick(ref, () => {
    setIsOpen(false);
  });

  const onLogout = () => dispatch({ type: LOGOUT });

  const onClickAccountSetting = () => {
    history.push({
      pathname: PAGE_URLS.SETTINGS,
    });

    setIsOpen(!isOpen);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const handleTermsClose = () => {
    setIsTermsOpen(false);
    setTermsAnchorEl(null);
  };

  const handleMenuItemClick = (event) => {
    if (!isOpen) {
      setAnchorEl(event.currentTarget);
    }

    setIsOpen(!isOpen);
  };

  const handleTermsClick = (event) => {
    if (!isTermsOpen) {
      setTermsAnchorEl(event.currentTarget);
    }

    setIsTermsOpen(!isTermsOpen);
  };

  const openFilters = () => setFilterOpen(true);

  const changeTheme = () => setThemeUpdate(true);

  const handleSearchTextClick = () => {
    if (!searchValue.length) return;

    if (
      isFlexPage
        ? history.location.pathname !== PAGE_URLS.FLEX_PAY_INVOICES_LIST
        : history.location.pathname !== PAGE_URLS.SUBSCRIBER_LIST
    )
      isFlexPage
        ? history.push(PAGE_URLS.FLEX_PAY_INVOICES_LIST)
        : history.push(PAGE_URLS.SUBSCRIBER_LIST);

    let filterObj = {
      key: 'text',
      value: searchValue,
    };

    if (Object.keys(searchType).length) {
      let searchVal = searchValue.split(':')?.[1]?.trim();

      filterObj = {
        key: searchType?.key,
        value: searchVal,
      };
    } else if (searchValue.indexOf(':') !== -1) {
      const searchArray = searchValue.split(':');
      const searchKey = searchArray?.[0]
        ?.trim()
        .toLowerCase()
        .replace(/ /g, '');
      const searchVal = searchArray?.[1]?.trim();

      const sType =
        searchKey &&
        (isFlexPage
          ? FLEX_SEARCH_MATCHES[searchKey]
          : GLOBAL_SEARCH_MATCHES[searchKey]);

      if (sType) {
        filterObj = {
          key: sType,
          value: searchVal,
        };
      } else {
        filterObj.value = searchValue;
      }
    } else if (searchValue && !Number.isNaN(Number(searchValue))) {
      const searchLength = searchValue.length;
      const searchType = (
        isFlexPage ? FLEX_SEARCH_SUGGESTIONS : GLOBAL_SEARCH_SUGGESTIONS
      )?.find((x) =>
        searchLength >= 0 && searchLength < 10
          ? x.key === 'patient_id'
          : searchLength === 10 && x.key === 'phone',
      );

      if (searchType) {
        filterObj = {
          key: searchType.key,
          value: searchValue,
        };
      }
    }

    if (!filterObj?.value) return;

    const {
      partner_id: pId,
      group_id: gId,
      region_id: rId,
    } = user?.settings?.level_data || {};

    let customFilter = isFlexPage ? invoiceFilterState : subscriberFilterState;

    let updatedFilters = {
      partner_id: customFilter?.partner_id || pId || '',
      group_id: customFilter?.group_id || gId || '',
      region_id: rId || '',
    };

    const INTERNAL_USERS = Utils.checkIfInternalUser(user);

    if (INTERNAL_USERS)
      updatedFilters = {
        partner_id: 'ALL_PARTNERS',
        group_id: 'ALL_GROUPS',
        region_id: '',
      };

    isFlexPage
      ? dispatch({
          type: UPDATE_INVOICE_FILTERS,
          payload: {
            filters: {
              ...invoiceFilters,
              filterState: {
                ...customFilter,
                text: '',
                ...updatedFilters,
                location_id: '',
                first_name: '',
                last_name: '',
                email: '',
                patient_id: '',
                statStaus: '',
                status: '',
                invoice_no: '',
                created_start: null,
                created_end: null,
                dateLabel: '',
                [filterObj.key]: filterObj.value,
              },
              pageProps: {
                page_number: 0,
                rowsPerPage: 100,
              },
              isFilterUpdated: true,
            },
          },
        })
      : dispatch({
          type: SUBSCRIBERS_FILTERS_UPDATED,
          payload: {
            subscriberFilters: {
              ...subscriberFilters,
              filterState: {
                ...customFilter,
                text: '',
                ...updatedFilters,
                location_id: '',
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                patient_id: '',
                subscription_id: '',
                date_of_birth: '',
                created_start: null,
                created_end: null,
                year: null,
                statStatus: '',
                [filterObj.key]: filterObj.value,
              },
              pageProps: {
                page_number: 0,
                rowsPerPage: 100,
              },
              isFilterUpdated: true,
            },
          },
        });

    setSearchType({});
    setSearchValue('');

    document.getElementById('search-auto-complete').blur();
  };

  const renderAutoTextField = (params) => {
    const { InputProps = {}, inputProps = {}, ...restProps } = params;

    return (
      <TextField
        fullWidth
        variant="outlined"
        name="search"
        className={styles.globalSearch}
        placeholder="Search Subscribers"
        InputProps={{
          ...InputProps,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={openFilters}
                data-testid="header-open-filter-icon"
              >
                <TuneIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{
          'data-testid': 'search-subscriber-input',
          ...inputProps,
          className: clsx(inputProps?.className, styles.searchTextfield),
        }}
        {...restProps}
      />
    );
  };

  const renderCustomOption = (props) => {
    const { key = '', value = '', example = '' } = props;

    return (
      <Grid container key={key} className={styles.searchOptionCont}>
        <Grid item xs={12} sm={4}>
          <span className={styles.searchDescription}> {value} </span>
        </Grid>
        <Grid item xs={12} sm={8} className={styles.searchExample}>
          {example}
        </Grid>
      </Grid>
    );
  };

  const renderTermsItems = (label, linkTo) => (
    <MenuItem
      onClick={handleTermsClose}
      data-testid={`header-${label}`}
      key={`header-${label}`}
    >
      <Link
        to={`${linkTo}`}
        className={styles.termItems}
        data-testid={`link-${label}`}
      >
        {label}
      </Link>
    </MenuItem>
  );

  const onCreateSubEvent = () =>
    history.push({
      pathname: PAGE_URLS.SUBSCRIBER_CREATE,
      state: {
        group_id: groupId,
        partner_id: partnerId,
        previousPath: '/subscriber/list',
      },
    });

  const hideCreateButton =
    history.location.pathname === PAGE_URLS.SUBSCRIBER_CREATE ||
    history.location.pathname === PAGE_URLS.SUBSCRIBER_LIST;

  const renderTermsMenu = () => {
    return (
      <>
        <Tooltip arrow title="Legal">
          <IconButton
            onClick={handleTermsClick}
            className={styles.termsHeaderButton}
            disableRipple={true}
            data-testid="header-terms-button"
          >
            <img src={termsIcon} alt="Terms" />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={termsAnchorEl}
          getContentAnchorEl={null}
          keepMounted
          open={isTermsOpen}
          onClose={handleTermsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {termsMenu.map((terms) =>
            renderTermsItems(terms?.title, terms?.linkTo),
          )}
        </Menu>
      </>
    );
  };

  const isFlexPage = selected_product?.key === 'flex';

  return (
    <AppBar
      className={clsx(styles.header, styles.primaryAppBar, 'header-appbar')}
      elevation={0}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Link to="/dashboard" data-testid="header-dashboard-link">
            <img src={logo} alt={logoAlt} className={styles.logo} />
          </Link>
        </Grid>

        <Grid
          item
          className={clsx('flex-grow flex-shrink', styles.searchWrapper)}
        >
          <Hidden xsDown>
            <Autocomplete
              id="search-auto-complete"
              size="small"
              name="header-autocomplte"
              className={styles.globalAutoComplete}
              options={
                isFlexPage ? FLEX_SEARCH_SUGGESTIONS : GLOBAL_SEARCH_SUGGESTIONS
              }
              renderOption={renderCustomOption}
              getOptionLabel={(option) => option.value || ''}
              groupBy={(option) => option.groupKey || ''}
              renderInput={renderAutoTextField}
              value={searchType}
              onChange={(evt, newValue) => newValue && setSearchType(newValue)}
              inputValue={searchValue}
              onInputChange={(_evt, newSearchTerm) =>
                setSearchValue(newSearchTerm)
              }
              onKeyDown={(evt) => evt.keyCode === 13 && handleSearchTextClick()}
            />
          </Hidden>
        </Grid>
        {!hideCreateButton && (
          <Grid item>
            <Grid container direction="row">
              <Grid item>
                <Tooltip arrow title="Create Subscriber">
                  <IconButton
                    onClick={onCreateSubEvent}
                    className={styles.termsHeaderButton}
                    disableRipple={true}
                    data-testid="header-create-button"
                  >
                    <img src={createIcon} alt="Create" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item className={styles.termsContainer}>
                {renderTermsMenu()}
              </Grid>
              <Grid
                item
                style={{
                  margin: '0px 8px 0px 20px',
                  borderLeft: '1px solid rgba(111, 108, 153, 0.20)',
                }}
              ></Grid>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Button
            disableElevation
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={handleMenuItemClick}
            startIcon={
              <Avatar
                name={user?.name || ''}
                size="40"
                round={true}
                color={`
                  linear-gradient(95.69deg, 
                  var(--theme-highlight-color) 4.34%, 
                  var(--theme-highlight-color-bg) 55.62%, 
                  var(--theme-highlight-color-2) 91.79%)
                `}
              />
            }
            data-testid="header-avatar-button"
            style={{ minWidth: 'unset', backgroundColor: 'transparent' }}
          />

          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            keepMounted
            open={isOpen}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <RoleValidator
              roles={[PARTNER_ADMIN, GROUP_ADMIN, REGION_ADMIN, LOCATION_ADMIN]}
            >
              <MenuItem
                onClick={onClickAccountSetting}
                data-testid="header-account-settings"
              >
                <ListItemIcon>
                  <SettingsIcon className="menuIcons" />
                  <Typography variant="body1">Account Settings</Typography>
                </ListItemIcon>
              </MenuItem>
            </RoleValidator>

            <MenuItem onClick={changeTheme} data-testid="header-change-theme">
              <ListItemIcon>
                <ColorLensIcon className="menuIcons" />
                <Typography variant="body1">Change Theme</Typography>
              </ListItemIcon>
            </MenuItem>

            <MenuItem onClick={onLogout} data-testid="header-logout">
              <ListItemIcon>
                <ExitToAppIcon className="menuIcons" />
                <Typography variant="body1">Logout</Typography>
              </ListItemIcon>
            </MenuItem>

            <DividerWithText />

            <ListItemIcon style={{ padding: '0px 20px 8px' }}>
              <div className="d-flex flex-column">
                <Typography
                  variant="body1"
                  align="left"
                  className={styles.userName}
                >
                  {user?.name || ''}
                </Typography>

                <Typography
                  variant="body2"
                  align="left"
                  style={{ lineHeight: '1rem' }}
                >
                  {user && user['custom:role']
                    ? Utils.formatUserRole(user['custom:role'])
                    : ''}
                </Typography>
              </div>
            </ListItemIcon>
          </Menu>
        </Grid>
      </Grid>

      {isFilterOpen &&
        (isFlexPage ? (
          <FlexFilterModal
            isFilterOpen={isFilterOpen}
            closeFilterModal={() => setFilterOpen(false)}
          />
        ) : (
          <FilterModal
            isFilterOpen={isFilterOpen}
            closeFilterModal={() => setFilterOpen(false)}
          />
        ))}

      <ThemeChangeModal
        isOpen={updateTheme}
        onClose={() => setThemeUpdate(false)}
      />
    </AppBar>
  );
};

export default Header;
